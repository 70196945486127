.news-card-box {
  margin-bottom: 1.5rem;
  width: 100%;
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
  .news-top-bar {
    text-transform: capitalize;
    padding-top: 1.6rem;
    color: $white;
    @extend .d-flex, .justify-content-between, .align-items-center;

    @include media-breakpoint-down(lg) {
      // padding: 1.2rem 1.4rem;
    }
    @include media-breakpoint-down(md) {
      border-radius: 1rem 1rem 0px 0px;
    }

    &.bar-bg-1 {
      background-color: $green;
    }
    &.bar-bg-2 {
      background-color: $skyblue-light-200;
    }
    &.bar-bg-3 {
      background-color: $orange;
    }
    &.bar-bg-4 {
      background-color: $yellow-light;
    }
    &.bar-bg-5 {
      background-color: $primary;
    }

    .news-title-left {
      font-size: 2.7rem;
      font-weight: 600;
      @extend .d-flex, .justify-content-between, .align-items-center;
      @include media-breakpoint-down(md) {
        font-size: 2.2rem;
      }
      i {
        padding-right: 1.6rem;
        @include media-breakpoint-down(md) {
          font-size: 2rem;
        }
      }
      img {
        height: 2.7rem;
      }
      h2 {
        font-size: 2.7rem !important;
        font-weight: 600 !important;
        margin-bottom: 0 !important;
      }
    }

    .news-link {
      font-size: 1.9rem;
      @extend .d-flex,
        .justify-content-between,
        .align-items-center,
        .text-white;
      i {
        font-size: 1.2rem;
        padding-left: 1.2rem;
        padding-top: 0.8rem;
        padding-right: 0px !important;

        @include media-breakpoint-down(md) {
          font-size: 1.2rem;
        }
      }
      @include media-breakpoint-down(md) {
        font-size: 2rem;
      }
    }
  }
  .news-top-bar + .news-card-body {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .big-card-box {
    .col-xl-4 {
      @include media-breakpoint-down(xl) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @media (max-width: 767px) {
        display: block;
      }
      .headline-box-sm {
        @include media-breakpoint-down(xl) {
          width: calc(50% - 0.5rem);
        }
        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }
  }
  .news-card-body {
    padding: 3.2rem 2.4rem 2.2rem 3rem;
    border-radius: 1.5rem;
    overflow: hidden;
    display: flex;
    @extend .bg-white, .flex-wrap;
    &.bg-primary {
      background-color: $primary !important;
    }
    @media (max-width: 1440px) {
      padding: 1.9rem 1.6rem 0.6rem 1.5rem;
    }
    @media (max-width: 1199px) {
      padding: 1.9rem 1.9rem 1.05rem;
    }
    // @include media-breakpoint-down(lg) {
    //   padding: 1.931rem;
    // }
    @include media-breakpoint-down(md) {
      // padding: 1.9rem 1.5rem 1.05rem;
      padding: 0rem 1.5rem 1.05rem;
    }

    .cat-head-sec {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.7rem;
      @include media-breakpoint-down(md) {
        flex-wrap: wrap;
        margin-bottom: 1.5rem;
      }
      h2.title,
      h1.title {
        color: $primary;
        margin-bottom: 0;
        @include media-breakpoint-down(md) {
          font-size: 2.4rem;
          padding: 0 !important;
          margin-bottom: 0;
          margin-top: 0 !important;
        }
      }
      .subcate-sec {
        width: calc(100% - 24.3rem);
        position: relative;
        @include media-breakpoint-down(md) {
          display: none;
        }
        .sub-list {
          position: relative;
          width: auto !important;
          & > div {
            a {
              font-size: 2rem;
              color: $dark-gray-400;
              display: block;
              padding: 0.5rem 0.5rem;
              margin: 0 0.2rem;
              span {
                position: relative;
              }
            }
            &.active {
              a {
                font-weight: 600;
                color: $skyblue-light-200;
                span {
                  &::after {
                    content: "";
                    width: 100%;
                    height: 0.4rem;
                    border-radius: 0.8rem;
                    background-color: $skyblue-light-200;
                    position: absolute;
                    left: 0;
                    bottom: -0.5rem;
                  }
                }
              }
            }
          }
          &::after {
            content: "|";
            color: $dark-gray-400;
            position: absolute;
            right: 0rem;
            top: 50%;
            transform: translateY(-50%);
          }
          &:first-child {
            a {
              padding-left: 0;
              margin-left: 0;
            }
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
        .swiper {
          // padding-right: 7rem;
          .swiper-wrapper {
            // justify-content: end;
          }
        }
        .slider-nav {
          position: absolute;
          width: 7rem;
          top: 8px;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: $white;
          z-index: 1;
          .prev,
          .next {
            width: 3rem;
            height: 3rem;
            background-color: $gray-light-800;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            i {
              font-size: 1rem;
              color: $primary;
            }
            &.swiper-button-disabled {
              i {
                color: $dark-gray-800;
              }
            }
            &.swiper-button-lock {
              display: none;
            }
            .swiper-button-lock {
              display: none;
            }
          }
        }
      }
      .m-sub-cate {
        display: none;
        @include media-breakpoint-down(md) {
          display: block;
          width: 100%;
          margin-top: 1rem;
          min-height: 30px;
        }
        ul {
          margin: 0;
          padding: 0;
          li {
            display: inline-block;
            margin-right: 3rem;
            margin-bottom: 0.5rem;
            position: relative;
            &::after {
              content: "|";
              color: $dark-gray-400;
              position: absolute;
              right: -1.7rem;
              top: 50%;
              transform: translateY(-50%);
            }
            &:last-child {
              &::after {
                display: none;
              }
            }
            a {
              font-size: 1.6rem;
              color: $dark-gray-400;
              @include media-breakpoint-down(md) {
                font-size: 2rem;
              }
              &.active {
                font-weight: 600;
                color: $skyblue-light-200;
              }
            }
          }
        }
      }
    }

    .headline-wrap {
      @extend .col;

      .headline-slider {
        .carousel {
          margin-bottom: 4.2rem;
          @media (max-width: 1440px) {
            margin-bottom: 3.03rem;
          }
          @media (max-width: 767px) {
            margin-bottom: 5rem;
          }
          .carousel-indicators {
            bottom: -4.2rem;
            margin-bottom: 0.8rem;

            [data-bs-target] {
              background-color: $gray-light-400;
              width: 5.6rem;
              height: 0.5rem;
              // margin: 0 0.75rem;
              border-radius: 0.4rem;
              &.active {
                background-color: $green;
              }
            }
            @media (max-width: 1440px) {
              bottom: -3.5rem;
              [data-bs-target] {
                width: 3.98rem;
                height: 0.355rem;
              }
              @include media-breakpoint-down(md) {
                bottom: -4.745rem;
              }
            }
          }
          .carousel-control-prev {
            left: 3rem;
          }
          .carousel-control-next {
            right: 1.9rem;
          }
          .carousel-control-prev,
          .carousel-control-next {
            opacity: 1;
            width: 5.5rem;
            height: 5.5rem;
            padding: 0;
            border-radius: 50%;
            background-color: #ffffff;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: none;
            .carousel-control-prev-icon {
              background-color: transparent;
              background-image: none;
              width: 2.1rem;
              height: 2.1rem;
              &::after {
                content: "\e948";
                font-family: "icomoon" !important;
                color: $primary;
              }
            }
            .carousel-control-next-icon {
              &::after {
                content: "\e90a";
                font-family: "icomoon" !important;
                color: $primary;
              }
            }
            @media (max-width: 1441px) {
              width: 3.9rem;
              height: 3.9rem;
            }
            @include media-breakpoint-down(md) {
              display: none;
            }
          }
        }
        &:hover {
          .carousel {
            .carousel-control-prev,
            .carousel-control-next {
              display: flex;
              @include media-breakpoint-down(md) {
                display: none;
              }
            }
          }
        }
      }
    }

    .headline-sidebar {
      flex: 0 0 33.646%;
      min-width: 30rem;
      padding-left: 1rem;

      @include media-breakpoint-down(xl) {
        flex: 0 0 100%;
        padding-left: 0;
      }

      .add-space-news {
        margin-bottom: 2.9rem;
      }
    }
  }
  .infinite-scroll-component {
    overflow-x: hidden !important;
  }
}
.gallery-list-card {
  .headline-box {
    margin-bottom: 1rem;
  }
}
.gallery-list-sidebar {
  border-radius: 1.2rem;
  background-color: $white;

  .gallery-list-topbar {
    font-size: 2rem;
    padding: 2.4rem 1.5rem;
    background-color: $yellow-light;
    color: $white;
    border-radius: 1.2rem 1.2rem 0 0;
    @extend .text-white, .fw-semibold;
  }

  .gallery-list-body {
    padding: 0.6rem 1.2rem;

    h4 {
      font-size: 1.8rem;
      padding: 1rem 0 0 0.3rem;
      margin-bottom: 1rem;
      @extend .text-primary, .fw-semibold;
    }
  }
}

.podcast-container {
  width: 21rem !important;
  @media (max-width: 1199px) {
    width: 18rem !important;
  }
  @media (max-width: 992px) {
    width: 19rem !important;
  }
  @media (max-width: 767px) {
    width: 21rem !important;
  }
  @media (max-width: 538px) {
    width: 20rem !important;
  }
  @media (max-width: 499px) {
    width: 19rem !important;
  }
  @media (max-width: 486px) {
    width: 21rem !important;
  }
  @media (max-width: 438px) {
    width: 20rem !important;
  }
  @media (max-width: 410px) {
    width: 21rem !important;
  }
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.podcast-title {
  font-size: 14px;
  word-wrap: break-word;

  @media (max-width: 767.9px) {
    font-size: 12px;
  }
}

.podcast-description {
  font-size: 12px;
  color: gray;
  word-wrap: break-word;

  @media (max-width: 767.9px) {
    font-size: 11px;
  }
}

.podcast-time {
  font-size: 12px;
  color: whitesmoke;
}

.podcast-mic {
  font-size: 16px;
  color: whitesmoke;
}

.podcast-img-container {
  min-width: 100% !important;
  height: 21rem !important;
  @media (max-width: 1199px) {
    height: 18rem !important;
  }
  @media (max-width: 992px) {
    height: 19rem !important;
  }
  @media (max-width: 767px) {
    height: 21rem !important;
  }
  @media (max-width: 538px) {
    height: 20rem !important;
  }
  @media (max-width: 499px) {
    height: 19rem !important;
  }
  @media (max-width: 486px) {
    height: 21rem !important;
  }
  @media (max-width: 438px) {
    height: 20rem !important;
  }
  @media (max-width: 410px) {
    height: 21rem !important;
  }
  overflow: hidden !important;

  position: relative;
  text-align: center;
  img {
    // max-height: 22rem;
    min-width: 100% !important;
    object-fit: cover;
  }
}
.podcard-mic-bottom-left {
  position: absolute;
  bottom: 8px;
  left: 10px;
}

.podcard-img-centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.podcast-footer {
  color: gray;
  display: flex !important;
  justify-content: flex-start !important;
}

.slider-active {
  background-color: #016369 !important;
}
.slider-inactive {
  background-color: #cecdcd !important;
}

.topShow {
  background: #ffffff;
}

.react-multiple-carousel__arrow {
  //background: white !important;
  //display: none;
  z-index: 1 !important;
}

// .react-multiple-carousel__arrow--left::before {
//   color: #eb0e8c !important;
// }

// .react-multiple-carousel__arrow--right::before {
//   color: #eb0e8c !important;
// }

// .react-multiple-carousel__arrow,
// .react-multiple-carousel__arrow--left,
// .react-multiple-carousel__arrow--right {
//   display: none;
// }

// .react-multi-carousel-track:hover + .react-multiple-carousel__arrow {
//   display: block;
// }

// .react-multiple-carousel__arrow:hover + .react-multiple-carousel__arrow {
//   display: initial;
// }

.icon-bookmark,
.icon-add,
.icon-bookmark-fill,
.bi .bi-whatsapp,
.icon-share {
  color: #eb0e8c;
}

.react-multi-carousel-dot-list {
  padding: 10px 0 !important;
}

.epCardTitle {
  // @media (min-width: 0) {
  //   font-size: 1.1rem !important;
  // }

  // @media (min-width: 576px) {
  //   font-size: 1.2rem !important;
  // }

  // @media (min-width: 768px) {
  //   font-size: 1.4rem !important;
  // }

  // @media (min-width: 992px) {
  //   font-size: 1.6rem !important;
  // }

  // @media (min-width: 1200px) {
  //   font-size: 1.8rem !important;
  // }

  // @media (min-width: 1400px) {
  //   font-size: 2rem !important;
  // }

  line-height: 2.2rem !important;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 4.3rem;
  font-size: 1.8rem !important;
  font-weight: 500 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.epCardDescription {
  font-weight: 300 !important;
  @media (min-width: 0) {
    font-size: 1.1rem !important;
  }

  // @media (min-width: 576px) {
  //   font-size: 1.2rem !important;
  // }

  @media (min-width: 768px) {
    font-size: 1.2rem !important;
  }

  // @media (min-width: 992px) {
  //   font-size: 3rem !important;
  // }

  @media (min-width: 1200px) {
    font-size: 1.4rem !important;
  }

  // @media (min-width: 1400px) {
  //   font-size: 3rem !important;
  // }
}

.episodePic {
  cursor: pointer;
  min-height: 3.45rem !important;
  img {
    border-radius: 0.5rem;
  }

  @media (min-width: 0px) {
    height: 75px !important;
    width: 75px !important;
  }

  @media (min-width: 768px) {
    height: 70px !important;
    width: 70px !important;
  }

  @media (min-width: 992px) {
    height: 80px !important;
    width: 80px !important;
  }

  @media (min-width: 1200px) {
    height: 90px !important;
    width: 90px !important;
  }

  @media (min-width: 1400px) {
    height: 100px !important;
    width: 100px !important;
  }
}

.micDuration {
  display: flex;
  @media (min-width: 0) {
    font-size: 1rem !important;
    padding: 0 0.8rem !important;
    height: 1.5rem !important;
  }

  @media (min-width: 768px) {
    font-size: 1.4rem !important;
    padding: 0 1rem !important;
    height: 2rem !important;
  }

  @media (min-width: 1200px) {
    font-size: 1.6rem !important;
    // padding: 0 1.4rem !important;
  }
}

.epMicDuration {
  // margin-top: 10px !important;
  @media (min-width: 0) {
    font-size: 1rem !important;
  }

  // @media (min-width: 768px) {
  //   font-size: 1rem !important;
  // }

  @media (min-width: 1200px) {
    font-size: 1.2rem !important;
  }
}

.epMicIcon {
  margin-right: 0px !important;

  @media (min-width: 0) {
    margin-left: -3px !important;
  }

  @media (min-width: 768px) {
    margin-left: -5px !important;
  }

  @media (min-width: 1200px) {
    margin-left: -10px !important;
  }
}

.episodeTitle,
.showTitleLanding {
  // @media (min-width: 0) {
  //   font-size: 3.75vw !important;
  // }

  // @media (min-width: 400px) {
  //   font-size: 1.3rem !important;
  // }

  // @media (min-width: 430px) {
  //   font-size: 1.4rem !important;
  // }

  // @media (min-width: 450px) {
  //   font-size: 1.5rem !important;
  // }

  // @media (min-width: 475px) {
  //   font-size: 1.6rem !important;
  // }

  // @media (min-width: 500px) {
  //   font-size: 1.7rem !important;
  // }

  // @media (min-width: 525px) {
  //   font-size: 1.8rem !important;
  // }

  // @media (min-width: 576px) {
  //   font-size: 1.6rem !important;
  // }

  // @media (min-width: 768px) {
  //   font-size: 1.2rem !important;
  // }

  // @media (min-width: 992px) {
  //   font-size: 1.6rem !important;
  // }

  // @media (min-width: 1025px) {
  //   font-size: 1.2rem !important;
  // }

  // @media (min-width: 1200px) {
  //   font-size: 1.5rem !important;
  // }

  // @media (min-width: 1440px) {
  //   font-size: 1.8rem !important;
  // }

  line-height: 2.2rem !important;
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  max-height: 4.3rem;
  min-height: 4.3rem; // To align bookmark+share icons in audio episode cards LLT2483
  font-size: 1.8rem !important;
  font-weight: 500 !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.podcast-card {
  margin-bottom: 1.5rem !important;

  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.react-multiple-carousel__arrow {
  background: white !important;
  margin-bottom: 8.5rem;
  @media (max-width: 769px) {
    min-width: 35px !important;
    min-height: 35px !important;
  }
}

.react-multiple-carousel__arrow--right::before,
.react-multiple-carousel__arrow--left::before {
  color: #eb0e8c !important;
  @media (max-width: 769px) {
    width: 18.72px !important;
    // height: 22.79px !important;
  }
}

.react-multiple-carousel__arrow--right::before {
  @media (max-width: 769px) {
    margin-left: 3px !important;
  }
}

.top-show-container {
  width: 25rem !important;
  @media (max-width: 1199px) {
    width: 22rem !important;
  }
  @media (max-width: 992px) {
    width: 23rem !important;
  }
  @media (max-width: 767px) {
    width: 25rem !important;
  }
  @media (max-width: 538px) {
    width: 24rem !important;
  }
  @media (max-width: 499px) {
    width: 23rem !important;
  }
  @media (max-width: 486px) {
    width: 25rem !important;
  }
  @media (max-width: 438px) {
    width: 24rem !important;
  }
  @media (max-width: 410px) {
    width: 25rem !important;
  }
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.top-show-img-container {
  min-width: 100% !important;
  height: 20rem !important;
  @media (max-width: 1199px) {
    height: 17rem !important;
  }
  @media (max-width: 992px) {
    height: 18rem !important;
  }
  @media (max-width: 767px) {
    height: 20rem !important;
  }
  @media (max-width: 538px) {
    height: 19rem !important;
  }
  @media (max-width: 499px) {
    height: 18rem !important;
  }
  @media (max-width: 486px) {
    height: 20rem !important;
  }
  @media (max-width: 438px) {
    height: 19rem !important;
  }
  @media (max-width: 410px) {
    height: 20rem !important;
  }
  overflow: hidden !important;

  position: relative;
  img {
    // max-height: 22rem;
    position: absolute;
    min-width: 100% !important;
    object-fit: cover;
  }
}

.videoShowTopBar {
  display: flex;
  color: #eb0e8c !important;
  justify-content: space-between;
  padding: 1.6rem 0rem;
  border-radius: 1.5rem 1.5rem 0px 0px;
}

.videoTitle {
  font-size: 2.2rem;
  font-weight: 600;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  h2 {
    font-size: 2.7rem !important;
    font-weight: 600 !important;
    margin-bottom: 0 !important;
  }
}

.videoTitle img,
i {
  padding-right: 10px;

  height: 2.7rem;
  max-width: 100%;
  vertical-align: middle;
}
.icon-play-circle-shows {
  height: 1.7rem;
}
.menu-right-icon {
  height: 1.2rem;
}
.swiper-icon-arrow {
  padding-right: 0px;
}

.videoTitle span {
  vertical-align: middle;
}

.videoLink {
  font-size: 2rem;
  vertical-align: middle;
}

.videoLink i {
  font-size: 1.2rem;
  padding-left: 1.2rem;
  padding-right: 0px !important;
}

.react-multi-carousel-track li {
  padding-right: 10px;
}

.react-multi-carousel-list {
  width: 100%;
  padding: 0 !important;
}

.video-topshow .react-multi-carousel-track {
  margin-bottom: 1rem !important;

  // @media (min-width: 400px) {
  //   width: 2485px;
  // }

  // @media (min-width: 501px) {
  //   width: 2235px;
  // }

  // @media (min-width: 564px) {
  //   width: 2265px;
  // }

  // @media (min-width: 576px) {
  //   width: 2145px;
  // }

  // @media (min-width: 767px) {
  //   width: 2455px;
  // }

  // @media (min-width: 992px) {
  //   width: 2595px;
  // }

  // @media (min-width: 1201px) {
  //   width: 3275px;
  // }

  // @media (min-width: 1440px) {
  //   width: 3755px;
  // }

  // @media (min-width: 1800px) {
  //   width: 4615px;
  // }
}

.audio-ep-card {
  justify-content: flex-end !important;
}

.pd-bm {
  margin-bottom: 1.6rem !important;
}
.audio-card-container {
  // width: 200px;
  padding: 1rem 1rem 1rem 1rem;
  margin-bottom: 1rem;
  box-shadow: 1.13198px 2.26396px 4.52791px rgba(0, 0, 0, 0.1);
  border-radius: 1.5rem;

  .audio-card-image {
    border-radius: 1.5rem;
    // height: 21rem !importan
    height: 19rem;
    @media (min-width: 1199px) {
      height: 15rem !important;
    }
    @media (min-width: 1599px) {
      height: 18rem !important;
    }
    // @media (max-width: 767px) {
    //   height: 21rem !important;
    // }
    // @media (max-width: 538px) {
    //   height: 20rem !important;
    // }
    // @media (max-width: 499px) {
    //   height: 19rem !important;
    // }
    // @media (max-width: 486px) {
    //   height: 21rem !important;
    // }
    // @media (max-width: 438px) {
    //   height: 20rem !important;
    // }
    // @media (max-width: 410px) {
    //   height: 21rem !important;
    // }
  }
  .audio-card-content {
    margin-top: 1rem;
    .title {
      height: 5rem;
      color: black;
      line-height: 2.5rem;
      font-family: "Mukta", system-ui, -apple-system, BlinkMacSystemFont;
      font-style: normal;
      font-weight: 500;
      font-size: 18.1117px;
      white-space: break-spaces;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      // line-height: 3.2rem;
      max-height: 5rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .duration-row {
      display: flex;
      margin-top: 1rem;
      img {
        width: 2rem;
        height: 2rem;
      }
      .duration {
        margin-left: 1rem;
        font-family: "Mukta", system-ui, -apple-system, BlinkMacSystemFont;
        font-style: normal;
        font-weight: 400;
        font-size: 15.8447px;

        color: #868686;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .audio-card-container {
    min-width: 20rem !important;
    margin-right: 2rem;
  }
}
.audio-top-show-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}


.showCards-container {
  @media (min-width: 1199px) {
    width: 19.2%;
  }
  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
    box-shadow: 1.13198px 2.26396px 4.52791px rgba(0, 0, 0, 0.1);
    border-radius: 1.5rem;
    height: 8.4rem;
    line-height: 2rem;
    .left-column {
      margin: auto 0;
      .audio-title {
        font-family: "Mukta", system-ui, -apple-system, BlinkMacSystemFont;
        font-style: normal;
        font-weight: 500;
        font-size: 18.1117px;
        /* or 18px */

        text-transform: uppercase;

        color: #000000;
      }
      .episodes {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 15.8447px;

        color: #868686;
      }
    }
    img {
      width: 7rem;
      height: 7rem;
      border-radius: 0.5rem;
    }
  }
}
.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition;
}
@media screen and (max-width: 600px) {
  .audio-card-container .audio-card-content .title {
    line-height: 2.6rem;
    font-size: 14px;
  }
  .audio-card-container .audio-card-content .duration-row img {
    margin-top: 3px;
  }
  .audio-title {
    font-size: 14px !important;
  }
  .episodes {
    font-size: 12.8px !important;
  }
}
.manual-carousel-item {
  width: 20rem;
  margin-right: 2rem;
}
iframe[src*="youtube.com/embed"] {
  @media (max-width: 599px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
div:has(> iframe[src*="youtube.com/embed"]) {
  margin-bottom: 10px;

  @media (max-width: 599px) {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
  }
}

.test-sub{
  position: relative;
  // &::after {
  //   content: "|";
  //   color: $dark-gray-400;
  //   position: absolute;
  //   right: -0.5rem;
  //   top: 50%;
  //   transform: translateY(-50%);
  // }
  min-width: fit-content;
  a{
    font-size: 2rem;
    color: #999;
    display: block;
    padding: .5rem;
    margin: 0 .2rem;
    &.active{
      font-weight: 600;
    color: #6fcbd0;
    // border-bottom: 4px solid #6fcbd0;
    }
    &.active:after {
      content: '';
  
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 4px;
  
      border-width: 0 0 4px;
      border-style: solid;
  }
  }
 
}
.test-sub:not(:last-child):after{
  content: "|";
  color: $dark-gray-400;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.mobile-subcat::-webkit-scrollbar {
  display: none;
}
.slider-nav {
  // position: absolute;
  width: 7rem;
  // top: 8px;
  // right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  z-index: 1;
  .prev,
  .next {
    width: 3rem;
    height: 3rem;
    background-color: $gray-light-800;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      font-size: 1rem;
      color: $primary;
    }
    &.swiper-button-disabled {
      i {
        color: $dark-gray-800;
      }
    }
    &.swiper-button-lock {
      display: none;
    }
    .swiper-button-lock {
      display: none;
    }
  }
}


//new css for the cricket pages

.custom-matches-section {
  // font-family: Arial, sans-serif;
  margin-bottom: 20px;
}

.custom-section-title {
  // font-size: 18px;
  margin-bottom: 10px;
}

.custom-matches-carousel {
  display: flex;
  align-items: center;
  position: relative;
}

.custom-carousel-arrow {
  background-color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
}

.custom-carousel-arrow-left {
  left: 1px;
}

.custom-carousel-arrow-right {
  right: 1px;
}

.custom-matches-cards {
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  gap: 10px;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.custom-match-card-stats {
  // min-width: 300px;
  // max-width: 300px;
  flex : 1;
  border: 1px solid #ddd;
  border-radius: 10px;
  // padding: 10px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.custom-match-card {
  // min-width: 300px;
  // max-width: 300px;
  // flex : 1;
  flex: 0 0 33.33%; /* Ensures each card takes one-third of the container's width */
  max-width: 33.33%; /* Matches the flex-basis */
  border: 1px solid #ddd;
  border-radius: 10px;
  // padding: 10px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.custom-match-card-matches {
  // min-width: 300px;
  // max-width: 300px;
  flex : 1;
  // flex: 0 0 33.33%; /* Ensures each card takes one-third of the container's width */
  // max-width: 33.33%; /* Matches the flex-basis */
  border: 1px solid #ddd;
  border-radius: 10px;
  // padding: 10px;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-match-header-stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
  padding-top: 10px;
}
.custom-match-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 0px 10px;
  padding-top: 10px;
}

.custom-live-badge {
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 2px 5px;
  border-radius: 3px;
}

.custom-teams-scores p {
  display: flex;
  align-items: center;
  margin: 5px 0;
  padding: 0px 29px;
}

.custom-teams-scores img {
  width: 20px;
  height: 15px;
  margin-right: 5px;
}

.custom-match-status {
  font-size: 12px;
  color: gray;
  margin-top: 10px;
  background-color: #E0E1EB;
  text-align: center;
  margin-bottom: 0.6rem;
  padding: 10px 0px
  ;
}


.custom-cricket-dashboard{
  overflow: hidden;
  width: 100%;
}


.custom-team-logos {
  display: flex;
 
  gap: 10px;
  margin-top: 10px;
  overflow-x: auto;
  // flex-wrap: wrap;
  // flex-direction: column;
}

.custom-team-logo {
  text-align: center;
  display: flex;
  flex : 1;
  flex-direction: column;
  justify-content: center;
  border: 1px solid #DFDFDF;
  height: 120px;
  width: 120px;
  border-radius: 12px;

}

.custom-team-logo img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}


// classes for onyl team list 

.custom-points-table {
  // font-family: Arial, sans-serif;
  max-width: 100%;
  margin: 20px auto;
  // border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-points-title {
  // margin: 16px;
  // font-size: 18px;
  font-weight: 500;
  border-bottom : 4px solid #D9368B
}

.custom-group-tabs {
  display: flex;
  gap: 8px;
  padding: 0 16px 16px;
}

.custom-tab {
  border: 1px solid #ddd;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 0px 12px;
  font-size: 16px;
  cursor: pointer;
}

.custom-tab.active {
  background: #ec4899; /* Pink color */
  color: #fff;
}
.custom-tab.not-active {
  background: #c4c4c4; /* Pink color */
  // color: #fff;
}
.custom-tab.full-points {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
}

.custom-external-link {
  font-size: 12px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
}

.custom-table thead th {
  background: #f4f4f4;
  padding: 12px;
  font-weight: 600;
}

.custom-table tbody td {
  padding: 12px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  gap: 8px;
}

.custom-team-logo-table {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.custom-table-row-alt {
  background: #f9f9f9;
}

.custom-table tbody tr:last-child td {
  border-bottom: none;
}

.cricket-heading{
  font-size : 22px !important;
  border-bottom: 4px solid #D9368B !important;
}
.cricket-heading-points{
  font-size : 22px !important;
}
